/* variables end */
/* latter spacing converter in sass */
@font-face {
  font-family: "Gilroy";
    src: url("Fonts/Gilroy-ExtraBold.woff2") format("woff2"), url("Fonts/Gilroy-ExtraBold.woff") format("woff");
  font-weight: 800;
  font-display: swap;
}
@font-face {
  font-family: "Gilroy";
    src: url("Fonts/Gilroy-Bold.woff2") format("woff2"), url("Fonts/Gilroy-Bold.woff") format("woff");
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: "Gilroy";
    src: url("Fonts/Gilroy-SemiBold.woff2") format("woff2"), url("Fonts/Gilroy-SemiBold.woff") format("woff");
  font-weight: 600;
  font-display: swap;
}
@font-face {
  font-family: "Gilroy";
    src: url("Fonts/Gilroy-Medium.woff2") format("woff2"), url("Fonts/Gilroy-Medium.woff") format("woff");
  font-weight: 500;
  font-display: swap;
}
@font-face {
  font-family: "Gilroy";
    src: url("Fonts/Gilroy-Regular.woff2") format("woff2"), url("Fonts/Gilroy-Regular.woff") format("woff");
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: "Proxima Nova A";
    src: url("Fonts/ProximaNova-Black.woff2") format("woff2"), url("Fonts/ProximaNova-Black.woff") format("woff");
  font-weight: 900;
  font-display: swap;
}
@font-face {
  font-family: "Proxima Nova A";
    src: url("Fonts/ProximaNovaA-Bold.woff2") format("woff2"), url("Fonts/ProximaNovaA-Bold.woff") format("woff");
  font-weight: 700;
  font-display: swap;
}
@font-face {
  font-family: "Proxima Nova A";
    src: url("Fonts/ProximaNova-Light.woff2") format("woff2"), url("Fonts/ProximaNova-Light.woff") format("woff");
  font-weight: 300;
  font-display: swap;
}
@font-face {
  font-family: "Proxima Nova A";
    src: url("Fonts/ProximaNovaA-Regular.woff2") format("woff2"), url("Fonts/ProximaNovaA-Regular.woff") format("woff");
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: "Proxima Nova A";
    src: url("Fonts/ProximaNovaACond-Thin.woff2") format("woff2"), url("Fonts/ProximaNovaACond-Thin.woff") format("woff");
  font-weight: 200;
  font-display: swap;
}
html {
  background-color: #000;
}

  body #root{
  background-color: #000;
  font-family: "Gilroy", sans-serif;
  letter-spacing: normal;
}

* {
  box-sizing: border-box;
}

a {
  display: inline-block;
  text-decoration: none;
  color: inherit;
}

.gw-img {
  overflow: hidden;
  display: flex;
}
.gw-img img {
  width: 100%;
  max-width: 100%;
}

img {
  display: block;
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: 100%;
}

ul:not(.default) li {
  list-style: none;
}

.slick-same-height .slick-track {
  display: flex;
}
.slick-same-height .slick-track .slick-slide {
  height: inherit;
}

.win-container {
  margin: auto;
  width: 100%;
  padding: 0 15px;
  max-width: 1400px;
}
@media screen and (min-width: 992px) and (max-width: 1920px) {
  .win-container {
    max-width: 77.5vw;
    padding: 0 0.781vw;
  }
}
@media screen and (max-width: 991px) {
  .win-container {
    max-width: 710px;
  }
}
@media screen and (max-width: 767px) {
  .win-container {
    max-width: 510px;
  }
}
@media screen and (max-width: 575px) {
  .win-container {
    max-width: 100%;
  }
}

.row {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

  a, button {
  transition: 0.4s all !important;
  text-decoration: none;
}

.ratio {
  overflow: hidden;
  position: relative;
}
.ratio > img,
.ratio > video,
.ratio > iframe {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  object-position: center;
}

.ratio-1x1 {
  padding-top: 100%;
}

.ratio-4x3 {
  padding-top: 75%;
}

.ratio-16x9 {
  padding-top: 56.55%;
}

.ratio-3x2 {
  padding-top: 66.6667%;
}

.ratio-5x4 {
  padding-top: 80%;
}

.ratio-3x1 {
  padding-top: 33.33%;
}

.ratio-21x9 {
  padding-top: 42.857%;
}

.ratio-9x16 {
  padding-top: 115%;
}

/* All site css animation start here */
@-webkit-keyframes pulse {
  0% {
    -webkit-transform: translate(-50%, -50%) scale(0.75);
    transform: translate(-50%, -50%) scale(0.75);
    opacity: 1;
  }
    95%, to {
    -webkit-transform: translate(-50%, -50%) scale(2.8);
    transform: translate(-50%, -50%) scale(2.8);
    opacity: 0;
  }
}
@keyframes pulse {
  0% {
    -webkit-transform: translate(-50%, -50%) scale(0.75);
    transform: translate(-50%, -50%) scale(0.75);
    opacity: 1;
  }
    95%, to {
    -webkit-transform: translate(-50%, -50%) scale(2.8);
    transform: translate(-50%, -50%) scale(2.8);
    opacity: 0;
  }
}
@keyframes pulsAni {
    0%, 100% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1);
  }
}
@keyframes shadowAni1 {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.9);
  }
  40% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1.2);
  }
  50% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1.2);
  }
  60% {
    opacity: 0;
  }
  100% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.8);
  }
}
@keyframes shadowAni2 {
  0% {
    visibility: visible;
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.9);
  }
  20% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1.2);
  }
  50% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1.2);
  }
  70% {
    opacity: 0;
  }
  100% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.9);
  }
}
@-webkit-keyframes bounceDown {
  0% {
    -webkit-transform: translate(0, 0);
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  80% {
    -webkit-transform: translate(0, 15px);
    opacity: 0;
    @media screen and (min-width: 992px) and (max-width: 1920px) {
      -webkit-transform: translate(0, 0.781vw);
    }
  }
  100% {
    opacity: 0;
  }
}
@keyframes bounceDown {
  0% {
    transform: translate(0, 0);
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  80% {
    transform: translate(0, 15px);
    opacity: 0;
    @media screen and (min-width: 992px) and (max-width: 1920px) {
      transform: translate(0, 0.781vw);
    }
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes jiggle {
    0%, 100% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(-2deg);
  }
  75% {
    transform: rotate(2deg);
  }
}
@-moz-keyframes jiggle {
    0%, 100% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(-2deg);
  }
  75% {
    transform: rotate(2deg);
  }
}
@-o-keyframes jiggle {
    0%, 100% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(-2deg);
  }
  75% {
    transform: rotate(2deg);
  }
}
@keyframes jiggle {
    0%, 100% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(-2deg);
  }
  75% {
    transform: rotate(2deg);
  }
}
@keyframes flip {
  0% {
    transform: rotateY(0deg);
  }
  25% {
    transform: rotateY(180deg);
  }
  50% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(180deg);
  }
}
@-webkit-keyframes swirl {
  0% {
    -webkit-transform: rotate(0deg) translateX(60px) rotate(0deg);
    transform: rotate(0deg) translateX(60px) rotate(0deg);
  }
  to {
    -webkit-transform: rotate(1turn) translateX(60px) rotate(-1turn);
    transform: rotate(1turn) translateX(60px) rotate(-1turn);
  }
}
@keyframes swirl {
  0% {
    -webkit-transform: rotate(0deg) translateX(60px) rotate(0deg);
    transform: rotate(0deg) translateX(60px) rotate(0deg);
  }
  to {
    -webkit-transform: rotate(1turn) translateX(60px) rotate(-1turn);
    transform: rotate(1turn) translateX(60px) rotate(-1turn);
  }
}


@keyframes rotate-animation {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


@keyframes animate {
  15% {
    transform: skew(-12deg);
  }
  30% {
    transform: skew(10deg);
  }
  50% {
    transform: skew(-6deg);
  }
  65% {
    transform: skew(4deg);
  }
  80% {
    transform: skew(-2deg);
  }
  100% {
    transform: skew(0);
  }
}

@-webkit-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {-webkit-transform: translateY(0);}
  40% {-webkit-transform: translateY(-30px);}
  60% {-webkit-transform: translateY(-15px);}
}

@-moz-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {-moz-transform: translateY(0);}
  40% {-moz-transform: translateY(-30px);}
  60% {-moz-transform: translateY(-15px);}
}

@-o-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {-o-transform: translateY(0);}
  40% {-o-transform: translateY(-30px);}
  60% {-o-transform: translateY(-15px);}
}
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
  40% {transform: translateY(-30px);}
  60% {transform: translateY(-15px);}
}
/* All site css animation end here */
.win-heading {
  /* H1 */
  margin: 0;
  font-weight: 700;
  line-height: 1.08;
  color: #ffffff;
}
.win-heading--large {
  font-size: 110px;
}
.win-heading--main {
  font-size: 60px;
}
.win-heading--primary {
  font-size: 50px;
  line-height: 1.16;
  color: #ffffff;
}
.win-heading--tertiary {
  font-size: 22px;
  font-family: "Gilroy", sans-serif;
  line-height: 1.16;
  color: #ffffff;
}
@media screen and (min-width: 992px) and (max-width: 1920px) {
  .win-heading--large {
    font-size: 5.729vw;
  }
  .win-heading--main {
    font-size: 3.125vw;
  }
  .win-heading--primary {
    font-size: 3.472vw;
  }
  .win-heading--secondary {
    font-size: 1.528vw;
  }
}
@media screen and (max-width: 991px) {
  .win-heading--large {
    font-size: 32px;
  }
  .win-heading--main {
    font-size: 24px;
  }
  .win-heading--primary {
    font-size: 24px;
  }
  .win-heading--secondary {
    font-size: 20px;
  }
}

.win-text {
  font-family: "Gilroy", sans-serif;
  font-style: normal;
  margin: 0;
  font-weight: 400;
  line-height: 1.3;
  color: #ffffff;
}
.win-text--primary {
  font-size: 20px;
}
.win-text--secondary {
  font-size: 19px;
}
.win-text--large {
  font-size: 25px;
}
@media screen and (min-width: 992px) and (max-width: 1920px) {
  .win-text--primary {
    font-size: 1.042vw;
  }
  .win-text--secondary {
    font-size: 0.99vw;
  }
  .win-text--large {
    font-size: 1.302vw;
  }
}
@media screen and (max-width: 991px) {
  .win-text--primary {
    font-size: 14px;
  }
  .win-text--secondary {
    font-size: 12px;
  }
  .win-text--large {
    font-size: 18px;
  }
}

.win-subtitle {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.3;
  margin: 0;
  font-style: normal;
  color: #ffffff;
  font-family: "Gilroy", sans-serif;
}
@media screen and (min-width: 992px) and (max-width: 1920px) {
  .win-subtitle {
    font-size: 0.972vw;
  }
}
.win-subtitle--light {
  color: #ffffff;
}
.win-subtitle--primary {
  color: #8200fb;
}

.win-head .win-subtitle {
  margin-top: 0;
}
.win-head .win-subtitle + .win-heading {
  margin-top: 1.2rem;
}
.win-head .win-heading + .win-text {
  margin-top: 2.4rem;
}

.font-bold {
  font-weight: 700;
}

.font-reg {
  font-weight: 400;
}

.ta-center {
  text-align: center;
}

.ta-left {
  text-align: left;
}

.ta-right {
  text-align: right;
}

@media (min-width: 1200px) {
  .ta-xl-center {
    text-align: center;
  }
  .ta-xl-left {
    text-align: left;
  }
  .ta-xl-right {
    text-align: right;
  }
}
@media (min-width: 992px) {
  .ta-lg-center {
    text-align: center;
  }
  .ta-lg-left {
    text-align: left;
  }
  .ta-lg-right {
    text-align: right;
  }
}
@media (min-width: 768px) {
  .ta-md-center {
    text-align: center;
  }
  .ta-md-left {
    text-align: left;
  }
  .ta-md-right {
    text-align: right;
  }
}
.btn {
  display: inline-block;
  text-transform: uppercase;
  font-weight: 700;
  border-radius: 100px;
  border: 1px solid transparent;
}
.btn--font-secondary {
  font-family: "Proxima Nova A", sans-serif;
}
.btn--green {
  background-color: #7eb900;
  color: #ffffff !important;
  border-color: #7eb900;
}
.btn--green-shadow {
  margin: 0 5px 5px 0;
  box-shadow: 5px 5px 1px 0px rgba(94, 138, 0, 0.74);
}
@media screen and (max-width: 991px) {
  .btn--green-shadow {
    box-shadow: 3px 3px 1px 0px rgba(94, 138, 0, 0.74);
  }
}
.btn--text-dark {
  color: #000;
}
.btn--small {
  line-height: 1;
  font-size: 18px;
  padding: 10px 24px;
  letter-spacing: 1.5px;
}
@media screen and (min-width: 992px) and (max-width: 1920px) {
  .btn--small {
    font-size: 0.938vw;
    padding: 0.521vw 1.25vw;
    letter-spacing: 0.078vw;
  }
}
@media screen and (max-width: 991px) {
  .btn--small {
    font-size: 12px;
    padding: 5px 10px;
    letter-spacing: 0.5px;
  }
}
.btn--med {
  line-height: 1;
  font-size: 24px;
  padding: 26px 45px;
  letter-spacing: 0;
}
@media screen and (min-width: 992px) and (max-width: 1920px) {
  .btn--med {
    font-size: 1.25vw;
    padding: 1.354vw 2.344vw;
  }
}
@media screen and (max-width: 991px) {
  .btn--med {
    font-size: 14px;
    padding: 10px 15px;
  }
}
.btn--black-shadow {
  box-shadow: 11px 11px 35px 7px rgba(0, 0, 0, 0.77);
}
.btn.hover-effect--border:hover {
  background-color: transparent;
}
.btn--green-shadow:hover {
  box-shadow: unset;
  margin: 0 0 0 0;
}
.btn.jiggling {
  -webkit-animation: jiggle 0.15s ease-in-out infinite;
  -moz-animation: jiggle 0.15s ease-in-out infinite;
  -o-animation: jiggle 0.15s ease-in-out infinite;
  animation: jiggle 0.15s ease-in-out infinite;
}

.flashing {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -moz-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: flex-start;
  -moz-justify-content: flex-start;
  -ms-justify-content: flex-start;
  justify-content: flex-start;
  -ms-flex-pack: flex-start;
  text-align: left;
  border-radius: 100px;
  padding: 9px 26px 9px 11px;
}
@media screen and (min-width: 992px) and (max-width: 1920px) {
  .flashing {
    padding: 0.469vw 26px 9px 11px;
  }
}
.flashing--black {
  background-color: rgba(0, 0, 0, 0.24);
  border: 1px solid rgba(135, 135, 135, 0.24);
}
.flashing--purple {
  background-color: rgba(130, 0, 251, 0.502);
  border: 1px solid #878787;
}
.flashing--small {
  margin: auto;
  max-width: 967px;
}
@media screen and (min-width: 992px) and (max-width: 1920px) {
  .flashing--small {
    max-width: 50.365vw;
  }
}
.flashing__dot {
  display: inline-block;
  position: relative;
  border-radius: 100%;
  border: 1px solid transparent;
  width: 34px;
  min-width: 34px;
  height: 34px;
  margin-right: 20px;
}
@media screen and (min-width: 992px) and (max-width: 1920px) {
  .flashing__dot {
    width: 1.771vw;
    min-width: 1.771vw;
    height: 1.771vw;
    margin-right: 1.042vw;
  }
}
@media screen and (max-width: 991px) {
  .flashing__dot {
    width: 24px;
    min-width: 24px;
    height: 24px;
  }
}
.flashing__dot:before {
  content: "";
  position: absolute;
  width: 30px;
  height: 30px;
  top: 50%;
  left: 50%;
  border-radius: 100%;
  z-index: 1;
  -webkit-animation: pulse 2s ease-out infinite;
  animation: pulse 2s ease-out infinite;
}
@media screen and (min-width: 992px) and (max-width: 1920px) {
  .flashing__dot:before {
    width: 1.563vw;
    height: 1.563vw;
  }
}
@media screen and (max-width: 991px) {
  .flashing__dot:before {
    width: 20px;
    height: 20px;
  }
}
.flashing__dot--small:before {
  width: 30px;
  height: 30px;
}
@media screen and (min-width: 992px) and (max-width: 1920px) {
  .flashing__dot--small:before {
    width: 1.563vw;
    height: 1.563vw;
  }
}
@media screen and (max-width: 991px) {
  .flashing__dot--small:before {
    width: 20px;
    height: 20px;
  }
}
.flashing__dot--large:before {
  width: 40px;
  height: 40px;
}
@media screen and (min-width: 992px) and (max-width: 1920px) {
  .flashing__dot--large:before {
    width: 2.083vw;
    height: 2.083vw;
  }
}
@media screen and (max-width: 991px) {
  .flashing__dot--large:before {
    width: 30px;
    height: 30px;
  }
}
.flashing__dot--red {
  background-color: #f74424;
  border-color: #1f1c16;
}
.flashing__dot--red:before {
  background: #f74424;
}
.flashing__dot--green {
  background-color: #9ee700;
  border-color: #1f1c16;
  width: 43px;
  min-width: 43px;
  height: 43px;
}
@media screen and (min-width: 992px) and (max-width: 1920px) {
  .flashing__dot--green {
    width: 2.24vw;
    min-width: 2.24vw;
    height: 2.24vw;
  }
}
@media screen and (max-width: 991px) {
  .flashing__dot--green {
    width: 33px;
    min-width: 33px;
    height: 33px;
  }
}
.flashing__dot--green:before {
  background: #9ee700;
}
.flashing .win-text {
  display: inline-block;
  text-transform: uppercase;
  letter-spacing: 1.14px;
}
@media screen and (min-width: 992px) and (max-width: 1920px) {
  .flashing .win-text {
    letter-spacing: 0.059vw;
  }
}
  .flashing .win-text span, .flashing .win-text strong {
  font-weight: 800;
}

/* Header Start Here */
.win-header {
  position: absolute;
  z-index: 9;
  width: 100%;
  top: 0;
  left: 0;
}

.win-header .innerWrap {
  position: relative;
  width: 100%;
  padding: 49px 42px;
}
@media screen and (min-width: 992px) and (max-width: 1920px) {
  .win-header .innerWrap {
    padding: 2.552vw 2.188vw;
  }
}
@media screen and (max-width: 767px) {
  .win-header .innerWrap {
    padding: 20px 15px;
  }
}
.win-header .row {
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  margin: 0 !important;
}
.win-header__logo {
  text-align: center;
}
@media screen and (max-width: 991px) {
  .win-header__logo {
    text-align: left;
  }
}
.win-header__logo a {
  display: inline-block;
  width: 100%;
  max-width: 395px;
}
@media screen and (min-width: 992px) and (max-width: 1920px) {
  .win-header__logo a {
    max-width: 20.573vw;
  }
}
@media screen and (max-width: 991px) {
  .win-header__logo a {
    max-width: 250px;
  }
}
@media screen and (max-width: 767px) {
  .win-header__logo a {
    max-width: 150px;
  }
}

.win-header__ctc {
  position: absolute;
  top: 50%;
  right: 42px;
  transform: translateY(-50%);
}
.win-header__ctc .btn {
  color: #010102 !important;
  background-color: #9ee700;
    border-color:#9ee700;
}
@media screen and (max-width: 767px) {
  .win-header__logo {
    text-align: left;
    line-height: 0;
  }
  .win-header__ctc {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }
}

/* Header End Here */