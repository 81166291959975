



.features--heading-uppercase .Heading,
.caption .headline,
.Heading ,
.heading-1,
h1,h2,h3,h4,h5,h6,
a,span,
.Rte h1, .Rte h2, .Rte h3, .Rte h4, .Rte h5, .Rte h6,
.features--heading-uppercase .Rte h1, .features--heading-uppercase .Rte h2, .features--heading-uppercase .Rte h3, .features--heading-uppercase .Rte h4, .features--heading-uppercase .Rte h5, .features--heading-uppercase .Rte h6{
  letter-spacing:0;

}
div,input,label,p,select,span{
  letter-spacing: 0;
  margin: 0;
  padding: 0;
}
.Button, .shopify-payment-button__button--unbranded, .spr-button-primary:not(input), .spr-pagination-next>a, .spr-pagination-prev>a, .spr-summary-actions-newreview, input.spr-button-primary{
  	letter-spacing:0;
}
figure{
  margin: 0;
  padding: 0;
}